import React from "react";

const IOT_mobile_logo = () => {
  return (
    <svg
      width="37"
      height="43"
      viewBox="0 0 37 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_255_1009)">
        <path
          d="M18.5 0L0 6.68036V21.5269C0 38.3046 17.9527 42.8656 18.1339 42.9117L18.5 43L18.8661 42.9117C19.0473 42.8656 37 38.3046 37 21.5269V6.68036L18.5 0ZM32.375 21.5192C32.375 34.1005 18.9124 37.5252 18.7736 37.5597L18.5 37.625L18.2264 37.5597C18.0915 37.5252 4.625 34.1044 4.625 21.5192V10.3853L18.5 5.375L32.375 10.3853V21.5192Z"
          fill="white"
        />
        <path
          d="M6.85657 11.9364V21.5231C6.85657 26.8943 9.68167 30.2959 12.6841 32.4536C15.1777 34.2465 17.6945 35.095 18.5 35.3369C19.3094 35.095 21.8223 34.2465 24.3159 32.4536C27.3183 30.2959 30.1434 26.8905 30.1434 21.5231V11.9364L18.5 7.73242L6.85657 11.9364ZM13.6283 13.1189L16.6616 14.9464V18.3442L13.636 16.5167L13.6245 16.5129V13.1189H13.6283ZM9.98615 14.9503L13.0232 13.1074V16.509L13.0078 16.5129L9.99 18.3404L9.98615 14.9503ZM10.1943 18.9316L13.3316 17.0389L16.465 18.9316L13.3354 20.8321L10.1943 18.9316ZM15.4475 20.26L17.733 21.7842L17.1626 22.1336L14.8771 20.6055L15.4475 20.26ZM15.143 24.0647L18.1801 22.2219V25.6197L18.1647 25.6235L15.1469 27.451V24.0647H15.143ZM18.4884 29.9389L15.3473 28.0384L18.4846 26.1456L21.618 28.0384L18.4884 29.9389ZM18.7968 25.6235L18.7852 25.6197V22.2296V22.2257L21.8146 24.0532V27.451L18.7968 25.6235ZM19.8066 22.1297L19.2284 21.7804L21.5217 20.2562L22.0959 20.6017L19.8066 22.1297ZM23.9382 13.1189L26.9715 14.9464V18.3442L23.9459 16.5167L23.9344 16.5129V13.1189H23.9382ZM26.7749 18.9316L23.6453 20.8321L20.5042 18.9316L23.6415 17.0389L26.7749 18.9316ZM23.337 13.1074V16.509L23.3216 16.5129L20.3038 18.3365V14.9503L23.337 13.1074ZM19.6987 16.8814H17.2744V16.271H19.6987V16.8814Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_255_1009">
          <rect width="37" height="43" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IOT_mobile_logo;
