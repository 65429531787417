import React from "react";

export const LockIcon = () => {
  return (
    <svg
      width="43"
      height="54"
      viewBox="0 0 43 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.6765 19.8281H14.3294V11.1554C14.3294 7.3305 17.5444 4.21875 21.4961 4.21875C25.4478 4.21875 28.6628 7.3305 28.6628 11.1554V15.1875H32.8785V11.1554C32.8785 5.00428 27.7723 0 21.4961 0C15.2199 0 10.1137 5.00428 10.1137 11.1554V19.8281H6.32353C2.83674 19.8281 0 22.6669 0 26.1562V47.6719C0 51.1612 2.83674 54 6.32353 54H36.6765C40.1633 54 43 51.1612 43 47.6719V26.1562C43 22.6669 40.1633 19.8281 36.6765 19.8281ZM38.7843 47.6719C38.7843 48.835 37.8387 49.7812 36.6765 49.7812H6.32353C5.16126 49.7812 4.21569 48.835 4.21569 47.6719V26.1562C4.21569 24.9931 5.16126 24.0469 6.32353 24.0469H36.6765C37.8387 24.0469 38.7843 24.9931 38.7843 26.1562V47.6719Z"
        fill="#C9E265"
      />
    </svg>
  );
};
