import React from "react";

export const EyeIcon = () => {
  return (
    <svg
      width="43"
      height="37"
      viewBox="0 0 43 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.7064 17.5945C40.0964 14.1652 36.9617 11.1717 33.4185 8.72508L39.4784 2.64787C40.0885 2.05698 40.1054 1.08203 39.5162 0.470204C38.927 -0.141622 37.9548 -0.158588 37.3447 0.432303C37.332 0.444666 37.3194 0.4573 37.3069 0.470204L30.6866 7.10338C27.8686 5.548 24.716 4.70236 21.5 4.6392C9.82867 4.6392 0.677455 17.0647 0.2935 17.5944C-0.0978334 18.1343 -0.0978334 18.8656 0.2935 19.4055C2.90353 22.8348 6.03825 25.8283 9.58139 28.2749L3.52153 34.3521C2.91145 34.943 2.89453 35.918 3.48374 36.5298C4.07294 37.1416 5.04511 37.1586 5.65519 36.5677C5.66797 36.5553 5.68057 36.5427 5.69298 36.5298L12.3133 29.8966C15.1313 31.452 18.284 32.2976 21.4999 32.3608C33.1713 32.3608 42.3225 19.9353 42.7064 19.4055C43.0979 18.8657 43.0979 18.1344 42.7064 17.5945ZM11.8143 26.0403C8.68589 23.9507 5.88168 21.41 3.49237 18.5C5.75291 15.754 13.0843 7.71936 21.5 7.71936C23.8944 7.7679 26.2484 8.34643 28.3937 9.41343L25.7277 12.0871C22.2004 9.73856 17.4425 10.7023 15.1007 14.2397C13.3948 16.8165 13.3948 20.1677 15.1007 22.7445L11.8143 26.0403ZM25.6464 16.521C25.9469 17.1373 26.1045 17.814 26.1071 18.5C26.1071 21.0518 24.0444 23.1203 21.5 23.1203C20.8159 23.1177 20.1412 22.9597 19.5266 22.6583L25.6464 16.521ZM17.3536 20.479C17.0531 19.8627 16.8955 19.186 16.8929 18.5C16.8929 15.9482 18.9556 13.8797 21.5 13.8797C22.1841 13.8823 22.8588 14.0403 23.4734 14.3417L17.3536 20.479ZM21.5 29.2806C19.1057 29.2321 16.7517 28.6536 14.6063 27.5866L17.2723 24.9129C20.7996 27.2614 25.5575 26.2977 27.8993 22.7603C29.6052 20.1835 29.6052 16.8323 27.8993 14.2555L31.1857 10.9597C34.3141 13.0493 37.1183 15.5901 39.5076 18.5C37.247 21.246 29.9156 29.2806 21.5 29.2806Z"
        fill="#C9E265"
      />
    </svg>
  );
};
