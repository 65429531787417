import React from "react";

export const BoxIcon = () => {
  return (
    <svg
      width="47"
      height="53"
      viewBox="0 0 47 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.2138 12.6783L24.2744 0.20465C23.7946 -0.0682168 23.2056 -0.0682168 22.7257 0.20465L0.786333 12.6783C0.300051 12.9547 0 13.4692 0 14.0264V38.9736C0 39.5309 0.300051 40.0453 0.786333 40.3217L22.7257 52.7953C22.9657 52.9318 23.2328 53 23.5 53C23.7672 53 24.0345 52.9318 24.2743 52.7953L46.2137 40.3217C46.6999 40.0453 47 39.5308 47 38.9736V14.0264C47 13.4691 46.7 12.9547 46.2138 12.6783ZM23.5 3.34117L42.294 14.0264L23.5 24.7117L4.70614 14.0264L23.5 3.34117ZM3.1212 16.702L21.9394 27.4011V48.7716L3.1212 38.0726V16.702ZM25.0606 48.7716V27.4011L43.8788 16.702V38.0726L25.0606 48.7716Z"
        fill="#C9E265"
      />
    </svg>
  );
};
