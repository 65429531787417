import React from "react";

const Discord = () => {
  return (
    <svg
      width="31"
      height="27"
      viewBox="0 0 31 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_542_3)">
        <path
          d="M22.2436 0H8.75953C8.0391 0 7.37433 0.377963 7.01257 0.993678L0.269001 12.5063C-0.0896669 13.122 -0.0896669 13.878 0.269001 14.4937L7.00948 26.0063C7.36814 26.622 8.03601 27 8.75643 27H22.2405C22.9609 27 23.6257 26.622 23.9874 26.0063L30.7279 14.4937C31.0866 13.878 31.0866 13.122 30.7279 12.5063L23.9874 0.993678C23.6288 0.377963 22.9609 0 22.2405 0H22.2436Z"
          fill="#C9E265"
        />
        <g clip-path="url(#clip1_542_3)">
          <path
            d="M21.3913 8.09513C20.3077 7.59798 19.1458 7.23169 17.931 7.02188C17.9088 7.01783 17.8867 7.02795 17.8753 7.04817C17.7259 7.31396 17.5604 7.66067 17.4445 7.9332C16.1378 7.73757 14.8379 7.73757 13.558 7.9332C13.4421 7.65463 13.2705 7.31396 13.1204 7.04817C13.109 7.02862 13.0869 7.01849 13.0648 7.02188C11.8506 7.23099 10.6887 7.59729 9.60451 8.09513C9.59511 8.09918 9.58708 8.10593 9.58173 8.11469C7.37784 11.4073 6.77408 14.619 7.07025 17.7908C7.07158 17.8063 7.08031 17.8211 7.09236 17.8306C8.54643 18.8984 9.95497 19.5467 11.3373 19.9764C11.3594 19.9831 11.3829 19.975 11.3969 19.9568C11.7239 19.5103 12.0154 19.0394 12.2653 18.5443C12.2801 18.5153 12.266 18.4809 12.2359 18.4694C11.7735 18.294 11.3333 18.0801 10.9098 17.8373C10.8763 17.8178 10.8736 17.7699 10.9044 17.7469C10.9935 17.6801 11.0827 17.6107 11.1678 17.5405C11.1832 17.5277 11.2046 17.525 11.2227 17.5331C14.0049 18.8033 17.0169 18.8033 19.7662 17.5331C19.7843 17.5243 19.8058 17.527 19.8218 17.5398C19.907 17.61 19.9961 17.6801 20.0859 17.7469C20.1167 17.7698 20.1147 17.8177 20.0812 17.8373C19.6577 18.0849 19.2174 18.294 18.7544 18.4687C18.7243 18.4801 18.7109 18.5152 18.7256 18.5442C18.9809 19.0387 19.2724 19.5095 19.5934 19.9561C19.6068 19.975 19.6309 19.9831 19.653 19.9763C21.0421 19.5467 22.4506 18.8984 23.9047 17.8305C23.9174 17.8211 23.9255 17.8069 23.9268 17.7914C24.2813 14.1244 23.3331 10.9391 21.4133 8.11532C21.4087 8.10593 21.4006 8.09918 21.3913 8.09513ZM12.6809 15.8595C11.8433 15.8595 11.1531 15.0905 11.1531 14.146C11.1531 13.2016 11.8299 12.4326 12.6809 12.4326C13.5385 12.4326 14.2221 13.2084 14.2087 14.146C14.2087 15.0905 13.5318 15.8595 12.6809 15.8595ZM18.3296 15.8595C17.492 15.8595 16.8019 15.0905 16.8019 14.146C16.8019 13.2016 17.4786 12.4326 18.3296 12.4326C19.1873 12.4326 19.8708 13.2084 19.8574 14.146C19.8574 15.0905 19.1874 15.8595 18.3296 15.8595Z"
            fill="black"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_542_3">
          <rect width="31" height="27" fill="white" />
        </clipPath>
        <clipPath id="clip1_542_3">
          <rect
            width="17"
            height="17"
            fill="white"
            transform="translate(7 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Discord;
