import React from "react";

export const CodeBlockIcon = () => {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M43 9.5C43 6.98 42 4.564 40.218 2.782C38.436 1 36.02 0 33.5 0H9.5C6.98 0 4.564 1 2.782 2.782C1 4.564 0 6.98 0 9.5V33.5C0 36.02 1 38.436 2.782 40.218C4.564 42 6.98 43 9.5 43H33.5C36.02 43 38.436 42 40.218 40.218C42 38.436 43 36.02 43 33.5V9.5ZM40 9.5V33.5C40 35.224 39.316 36.878 38.096 38.096C36.878 39.316 35.224 40 33.5 40H9.5C7.776 40 6.122 39.316 4.904 38.096C3.684 36.878 3 35.224 3 33.5V9.5C3 7.776 3.684 6.122 4.904 4.904C6.122 3.684 7.776 3 9.5 3H33.5C35.224 3 36.878 3.684 38.096 4.904C39.316 6.122 40 7.776 40 9.5Z"
        fill="#C9E265"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.3677 13.5477L18.1002 28.4701C17.8691 29.2847 18.246 30.1655 18.9448 30.437C19.6436 30.7064 20.3993 30.267 20.6323 29.4525L24.8997 14.5301C25.1309 13.7156 24.7539 12.8348 24.0551 12.5633C23.3563 12.2938 22.6006 12.7332 22.3677 13.5477Z"
        fill="#C9E265"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.8238 17.7943L30.8128 21.5001L26.8238 25.2059C26.1985 25.7846 26.1985 26.7279 26.8238 27.3066C27.447 27.8872 28.463 27.8872 29.0862 27.3066L34.2084 22.5504C34.8337 21.9698 34.8337 21.0304 34.2084 20.4498L29.0862 15.6937C28.463 15.113 27.447 15.113 26.8238 15.6937C26.1985 16.2723 26.1985 17.2156 26.8238 17.7943Z"
        fill="#C9E265"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.982 15.6937L8.71342 20.4498C8.19231 21.0304 8.19231 21.9698 8.71342 22.5504L12.982 27.3066C13.5013 27.8872 14.3479 27.8872 14.8672 27.3066C15.3883 26.7279 15.3883 25.7846 14.8672 25.2059L11.5431 21.5001L14.8672 17.7943C15.3883 17.2156 15.3883 16.2723 14.8672 15.6937C14.3479 15.113 13.5013 15.113 12.982 15.6937Z"
        fill="#C9E265"
      />
    </svg>
  );
};
