import React from "react";

export const CodeIcon = () => {
  return (
    <svg
      width="83"
      height="69"
      viewBox="0 0 83 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0686 25.7909C11.3155 25.7909 9.07654 28.0299 9.07654 30.783V39.8592C9.07654 41.6066 11.7995 41.6316 11.7995 39.8595V37.5903H16.3378V39.8594C16.3378 41.6267 19.0607 41.621 19.0607 39.8595V30.783C19.0607 28.0299 16.8217 25.7909 14.0686 25.7909ZM11.7995 34.8674V30.783C11.7995 29.5314 12.8171 28.5138 14.0686 28.5138C15.3202 28.5138 16.3378 29.5314 16.3378 30.783V34.8674H11.7995Z"
        fill="#99AC52"
      />
      <path
        d="M31.7679 30.783C31.7679 28.0299 29.5289 25.7909 26.7758 25.7909H23.1452C22.3935 25.7909 21.7837 26.4007 21.7837 27.1523V39.8592C21.7837 41.625 24.5066 41.622 24.5066 39.8595V35.775H26.7758C29.5289 35.775 31.7679 33.536 31.7679 30.783ZM26.7758 33.0521H24.5066V28.5138H26.7758C28.0273 28.5138 29.0449 29.5314 29.0449 30.783C29.0449 32.0345 28.0273 33.0521 26.7758 33.0521Z"
        fill="#99AC52"
      />
      <path
        d="M35.8523 25.8353C35.1716 25.8299 34.4908 26.2636 34.4908 27.1524V39.8593C34.4908 41.6086 37.2138 41.6245 37.2138 39.8596C37.2138 39.8596 37.2138 27.2349 37.2138 27.1528C37.2138 26.2852 36.5331 25.8407 35.8523 25.8353Z"
        fill="#99AC52"
      />
      <path
        d="M77.6043 0.376587H4.99209C2.239 0.376587 0 2.61558 0 5.36868V63.4584C0 66.2115 2.239 68.4505 4.99209 68.4505H77.6043C80.3574 68.4505 82.5964 66.2115 82.5964 63.4584V5.36868C82.5964 2.61558 80.3574 0.376587 77.6043 0.376587ZM4.99209 3.09954H77.6043C78.8559 3.09954 79.8734 4.11711 79.8734 5.36868V14.899H2.72296V5.36868C2.72296 4.11711 3.74053 3.09954 4.99209 3.09954ZM77.6043 65.7276H4.99209C3.74053 65.7276 2.72296 64.71 2.72296 63.4584V17.622H79.8734V63.4584C79.8734 64.71 78.8559 65.7276 77.6043 65.7276Z"
        fill="#99AC52"
      />
      <path
        d="M71.3961 40.0935C72.9136 39.2169 73.4348 37.2704 72.559 35.7538C72.559 35.7538 70.229 31.7199 70.2243 31.7119C69.3686 30.2416 67.3574 29.6988 65.8847 30.5489C64.692 31.2373 63.1475 30.3378 63.1475 28.9676C63.1475 27.2162 61.7222 25.7909 59.9707 25.7909H55.3012C53.5497 25.7909 52.1245 27.2161 52.1244 28.9674C52.1243 30.3475 50.5811 31.237 49.3874 30.549C47.8893 29.6855 45.9087 30.2184 45.0476 31.7119L42.7129 35.7538C41.8371 37.2703 42.3583 39.2166 43.8756 40.0934C45.0761 40.787 45.07 42.5658 43.8758 43.2561C42.3583 44.1327 41.8371 46.0792 42.7129 47.5958C42.7129 47.5958 45.0446 51.6325 45.0476 51.6377C45.9098 53.1302 47.896 53.665 49.3872 52.8007C50.5847 52.1066 52.1244 53.0095 52.1244 54.3819C52.1244 56.1334 53.5497 57.5587 55.3012 57.5587H59.9707C61.7221 57.5587 63.1473 56.1334 63.1474 54.382C63.1474 52.9984 64.6854 52.1093 65.8846 52.8006C67.3818 53.6636 69.3668 53.1383 70.2243 51.6377L72.559 47.5958C73.4347 46.0792 72.9136 44.1328 71.3963 43.2562C70.1948 42.5619 70.1988 40.7855 71.3961 40.0935ZM70.0346 45.6138C70.2509 45.7397 70.3271 46.0172 70.2013 46.2343L67.8666 50.2762C67.7407 50.4907 67.4624 50.5678 67.2461 50.4428C64.2641 48.7201 60.4245 50.9606 60.4245 54.3819C60.4245 54.6319 60.2206 54.8357 59.9707 54.8357H55.3012C55.0513 54.8357 54.8474 54.6319 54.8474 54.3819C54.847 50.9511 51.0063 48.7185 48.0258 50.4428C47.806 50.5678 47.5312 50.4907 47.4054 50.2762L45.0706 46.2343C44.9448 46.0172 45.021 45.7397 45.2373 45.6138C48.2063 43.8854 48.2163 39.4552 45.2373 37.7357C45.021 37.6098 44.9448 37.3324 45.0706 37.1152L47.4054 33.0734C47.5312 32.858 47.806 32.7809 48.0258 32.9067C51.0107 34.6342 54.8474 32.3977 54.8474 28.9676C54.8474 28.7177 55.0513 28.5138 55.3012 28.5138H59.9707C60.2206 28.5138 60.4245 28.7177 60.4245 28.9675C60.4247 32.4043 64.2645 34.6301 67.2461 32.9067C67.4624 32.7808 67.7407 32.858 67.8665 33.0734L70.2013 37.1152C70.3271 37.3324 70.2509 37.6097 70.0347 37.7356C67.0786 39.4568 67.0801 43.9085 70.0346 45.6138Z"
        fill="#99AC52"
      />
      <path
        d="M57.6359 34.8674C53.883 34.8674 50.8285 37.921 50.8285 41.6748C50.8285 45.4286 53.883 48.4822 57.6359 48.4822C61.3888 48.4822 64.4433 45.4286 64.4433 41.6748C64.4433 37.921 61.3888 34.8674 57.6359 34.8674ZM57.6359 45.7592C55.3845 45.7592 53.5514 43.9271 53.5514 41.6748C53.5514 39.4225 55.3845 37.5903 57.6359 37.5903C59.8873 37.5903 61.7203 39.4225 61.7203 41.6748C61.7203 43.9271 59.8873 45.7592 57.6359 45.7592Z"
        fill="#99AC52"
      />
      <path
        d="M55.8206 10.3608H73.9732C75.7146 10.3608 75.7498 7.6379 73.9737 7.63788C73.9737 7.63788 55.9564 7.63788 55.8214 7.63788C54.0849 7.63788 54.0514 10.3608 55.8206 10.3608Z"
        fill="#99AC52"
      />
      <path
        d="M8.62271 10.3608C9.37463 10.3608 9.98419 9.75128 9.98419 8.99936C9.98419 8.24743 9.37463 7.63788 8.62271 7.63788C7.87079 7.63788 7.26123 8.24743 7.26123 8.99936C7.26123 9.75128 7.87079 10.3608 8.62271 10.3608Z"
        fill="#99AC52"
      />
      <path
        d="M14.0686 10.3608C14.8206 10.3608 15.4301 9.75128 15.4301 8.99936C15.4301 8.24743 14.8206 7.63788 14.0686 7.63788C13.3167 7.63788 12.7072 8.24743 12.7072 8.99936C12.7072 9.75128 13.3167 10.3608 14.0686 10.3608Z"
        fill="#99AC52"
      />
      <path
        d="M19.5146 10.3608C20.2665 10.3608 20.876 9.75128 20.876 8.99936C20.876 8.24743 20.2665 7.63788 19.5146 7.63788C18.7626 7.63788 18.1531 8.24743 18.1531 8.99936C18.1531 9.75128 18.7626 10.3608 19.5146 10.3608Z"
        fill="#99AC52"
      />
      <path
        d="M16.8465 55.2346L12.3632 50.7513C12.3632 50.7513 16.8235 46.2909 16.8469 46.2677C18.045 45.0686 16.1513 43.1127 14.9213 44.3428L9.47541 49.7887C8.94358 50.3205 8.94358 51.1821 9.47541 51.7139C9.47541 51.7139 14.8909 57.1294 14.9212 57.1597C16.1261 58.3646 18.1309 56.519 16.8465 55.2346Z"
        fill="#99AC52"
      />
      <path
        d="M31.3689 44.3427C30.1625 43.1366 28.1994 45.0235 29.4437 46.2679L33.927 50.7512C33.927 50.7512 29.4437 55.2345 29.4434 55.2349C28.232 56.4471 30.1406 58.3881 31.3689 57.1597L36.8149 51.7138C37.3467 51.182 37.3467 50.3204 36.8149 49.7886C36.8149 49.7886 31.4024 44.3761 31.3689 44.3427Z"
        fill="#99AC52"
      />
      <path
        d="M25.3613 44.0982C24.7197 43.8694 23.9444 44.0431 23.669 44.8745C23.669 44.8745 20.0717 55.6663 20.0386 55.7657C19.4861 57.4229 22.0626 58.3088 22.6213 56.6279C22.6213 56.6279 26.2064 45.8725 26.2514 45.7373C26.5108 44.9585 26.0029 44.3271 25.3613 44.0982Z"
        fill="#99AC52"
      />
    </svg>
  );
};
